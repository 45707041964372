@import 'npm:@atlassian/aui-adg/lib/css/icons.css';
@import 'npm:@atlassian/aui-adg/lib/css/aui-iconfont.css';
@import 'npm:@atlassian/aui-adg/lib/css/adg-icons.css';
@import 'npm:@atlassian/aui-adg/lib/css/adg-iconfont.css';

/*
 When consuming just the above files, the font-family isn't set properly. This fixes that.
 We could consume the full dist css, but that includes a bunch of styles we don't care about.
*/
.aui-icon-small:before,
.aui-icon-large:before {
  font-family: 'Atlassian Icons';
  src: url('npm:@atlassian/aui-adg/lib/css/fonts/atlassian-icons.eot');
  src: url('npm:@atlassian/aui-adg/lib/css/fonts/atlassian-icons.eot?#iefix')
      format('embedded-opentype'),
    url('npm:@atlassian/aui-adg/lib/css/fonts/atlassian-icons.woff')
      format('woff'),
    url('npm:@atlassian/aui-adg/lib/css/fonts/atlassian-icons.ttf')
      format('truetype'),
    url('npm:@atlassian/aui-adg/lib/css/fonts/atlassian-icons.svg#atlassian-icons')
      format('svg');
  font-weight: normal;
  font-style: normal;
}
